import React from 'react'
import './Exhibitions.css';
import img1 from '../image/exhibi_img1.webp'
import img2 from '../image/exhibi_img2.webp'
import img3 from '../image/exhibi_img3.webp'
import img4 from '../image/exhibi_img4.webp'
import img5 from '../image/exhibi_img5.webp'
import img6 from '../image/exhibi_img6.webp'


function Exhibitions() {
  return (
                                <>
                                <div className='container exhibitions rounded'>

                                        <h3 className=' container   text-white text-center'> EXHIBITIONS            </h3>

                                </div>


                                        <div className='container mb-5 mt-5'>

                                        <div class="row row-cols-1 row-cols-md-3 g-4">
                                 <div className="col border-0">
                                <div className="card border-0 h-100" id='card'>
                                <img src={img1} class="card-img-top" alt="Skyscrapers"/>
                                <h5 className='text-center mt-3  fw-bold exhibi_head'>Exhibition 2019</h5>

                                </div>
                            </div>
                            <div class="col border-0">
                                <div class="card h-100 border-0" id='card'>
                                <img src={img2} class="card-img-top" alt="Los Angeles Skyscrapers"/>
                                <h5 className='text-center-2 mt-3  fw-bold exhibi_head'>Exhibition 2019</h5>

                                </div>

                            </div>
                            <div class="col border-0">
                                <div class="card h-100 border-0" id='card'>
                                <img src={img3}  class="card-img-top" alt="Palm Springs Road"/>
                                <h5 className='text-center mt-3 fw-bold  exhibi_head'>Exhibition 2019</h5>

                                </div>
                            </div>
                            </div>

                                        </div>
                                        <div className='container mb-5 mt-5'>

                                        <div class="row row-cols-1 row-cols-md-3 g-4">
                            <div class="col border-0">
                                <div class="card h-100 border-0">
                                <img src={img4} class="card-img-top" alt="Skyscrapers"/>
                                                               <h5 className='text-center mt-3  fw-bold exhibi_head'>Exhibition 2019</h5>

                               
                                </div>

                            </div>
                            <div class="col border-0">
                                <div class="card h-100 border-0">
                                <img src={img5} class="card-img-top" alt="Los Angeles Skyscrapers"/>
                                
                                <h5 className='text-center mt-3  fw-bold exhibi_head'>Exhibition 2019</h5>

                                </div>

                            </div>
                            <div class="col border-0">
                                <div class="card h-100 border-0">
                                <img src={img6}  class="card-img-top" alt="Palm Springs Road"/>
                                <h5 className='text-center mt-3  fw-bold exhibi_head'>Exhibition 2019</h5>

                                </div>
                            </div>
                            </div>

                                        </div>

                                </>
  )
}

export default Exhibitions
