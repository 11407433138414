import React from 'react';
import './AboutUs.css';
import About from '../image/about.jpg'
import Aboutusowl from './Aboutusowl';

const AboutUs = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='about-us'>



          <div className='col-12 w-100'>
            <div id='about-unique'>
                <div id='about-img' >
                  <img src={About} alt="about/image" id='about_us_div_change' />
                </div>
                <div>
                  <h2>Trusted Medical Gas Pipeline Specialists</h2>
                  <p id='content-about-us-page'>
                    M.R. Engg. Works Pvt. Ltd. (Formerly M.R. Engineering Works) is one of the leading manufacturers & suppliers of Medical Gas Pipeline System (MGPS). We take special care that Medical Gas Pipeline Systems (MGPS) is a patient's life support and its designing & installation require a precise technical expertise. Thus, continual skill development and innovation is central to our organization. Employing technological expertise in design and development of newer products for critical application we have carved out a special space in the Medical Gas Pipeline Systems (MGPS) industry which has led us to succeed in Indian & Foreign markets.
                  </p>
                </div>
              </div>
          </div>



          <div className='about-us-card'>


            {/* <div className='about-us-card-1 shadow'>
              <i className='fa-solid fa-hourglass-half about-us-icon'></i>
              <h2>Established in 1996</h2>
              <p>
                The company is now entering its 28th year and is proud of its
                growth and extended reputation as a major player in the
                worldwide family of Medical Gas Pipeline installers and users.
              </p>
            </div> */}


            {/* <div className='about-us-card-1 shadow'>
              <i className='fa-solid fa-earth-americas about-us-icon'></i>
              <h2>Globally Recognised</h2>
              <p>
                We supply 90% of the India market and export to over 75
                countries worldwide. Growth has been structured to meet our
                customers needs both in the India and the expanding overseas
                markets.
              </p>
            </div> */}


            {/* <div className='about-us-card-1 shadow'>
              <i className='fa-solid fa-trademark about-us-icon'></i>
              <h2>Our Brand</h2>
              <p>
              The M.R. Engg. Works brand is a proudly owned trademark of M.R. Engg. Works Pvt. Ltd. All M.R. Engg. Works products are distinctly marked with this brand name, reflecting our commitment to exceptional design and quality.
              </p>
            </div> */}



          </div>

          <Aboutusowl/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
