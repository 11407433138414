import React from 'react';
import img1 from "../src/image/client_logo1.webp";
import img2 from "../src/image/client_logo2.webp";
import img3 from "../src/image/client_logo3.webp";
import img4 from "../src/image/client_logo4.webp";
import img5 from "../src/image/client_logo5.webp";
import img6 from "../src/image/client_logo6.webp";
import img7 from "../src/image/client_logo7.webp";
import img8 from "../src/image/client_logo8.webp";
import img9 from "../src/image/client_logo9.webp";
import img10 from "../src/image/client_logo10.webp";
import img11 from "../src/image/client_logo11.webp";
import img12 from "../src/image/client_logo12.webp";
import img13 from "../src/image/client_logo13.webp";
import img14 from "../src/image/client_logo14.webp";
import img15 from "../src/image/client_logo15.webp";
import img16 from "../src/image/client_logo16.webp";
// import img1 from "../src/image/client_logo1.webp";
import './Client.css';  // Import the custom CSS file

function Client() {
  return (
    <div className=''>
      {/* Repeat this block as needed */}
      <div className='client_images_div mb-3'>
        <div className='card card_images'>
          <img src={img1} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images '>
          <img src={img2} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img3} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img4} className='card-img-top' alt='...' />
        </div>
      </div>
      {/* Add more divs with className 'client_images_div' if needed */}
      {/* Repeat this block as needed */}
      <div className='client_images_div mb-3'>
        <div className='card card_images'>
          <img src={img5} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img6} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img8} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img7} className='card-img-top' alt='...' />
        </div>
      </div>
      {/* Add more divs with className 'client_images_div' if needed */}
      {/* Repeat this block as needed */}
      <div className='client_images_div mb-3'>
        <div className='card card_images'>
          <img src={img9} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img10} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img11} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img12} className='card-img-top' alt='...' />
        </div>
      </div>
      {/* Add more divs with className 'client_images_div' if needed */}
      {/* Repeat this block as needed */}
      <div className='client_images_div mb-3'>
        <div className='card card_images'>
          <img src={img13} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img14} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img15} className='card-img-top' alt='...' />
        </div>
        <div className='card card_images'>
          <img src={img16} className='card-img-top' alt='...' />
        </div>
      </div>
      {/* Add more divs with className 'client_images_div' if needed */}
    </div>
  );
}

export default Client;
