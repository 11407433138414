import React from "react";
import "./BlogPage.css";
import img1 from "../image/blog1.webp"
import img2 from "../image/blog2.webp"
import img4 from "../image/Gallery1.webp"
import img5 from "../image/Gallery2.webp"
import img6 from "../image/Gallery3.webp"
import galary1 from "../image/galary img1.jpg"
import galary2 from "../image/galary img2.jpg"
import galary3 from "../image/gal.jpg"
import galary4 from "../image/blog_about.jpg"


const BlogPage = () => {
  return (
    <>
       <div className='col-12 w-100 mb-4'>
            <div id='about-unique'>
                <div id='about-img' >
                  <img src={galary4} alt="about/image" id='about_us_div_change' />
                </div>
                <div>
                  <h1 className=" text-center mt-3 mb-3 fw-bolder" id="galary_head" style={{"color":"#1B9596"}}>Welcome to the Medical Gas Pipeline Blog</h1>
                  <p id='content-about-us-page'>
                  Dedicated to professionals in the medical Gas pipeline manufacturing industry and our blog provides comprehensive insights into pipeline systems. We explore the best practices in manufacturing, cutting-edge technological advancements, and important regulatory updates. Stay informed and ahead with our expert analysis and industry news.
                  </p>
                </div>
              </div>
          </div>





    
    <div className="blog-container">

      {/* Hero Section */}
      {/* <section className="hero-section rounded">
        <h2 className="mb-4">Welcome to the Medical Gas Pipeline Blog</h2>
        <p className="main-p-blog">
            This blog is designed for professionals in the medical Gas pipeline manufacturing industry, offering detailed insights and updates on pipeline systems. We cover all things from manufacturing best practices and latest technological innovations to industry standards and regulatory updates.
        </p>
      </section> */}

      {/* Introduction Section */}

     




{/* 
        <section id="about" className="introduction">
        <img  className="about_blog_img rounded" src={galary4} />    

        <h2 className="fw-bolder">About Our Blog</h2>
        <p>
            Dedicated to professionals in the medical Gas pipeline manufacturing industry and our blog provides comprehensive insights into pipeline systems. We explore the best practices in manufacturing, cutting-edge technological advancements, and important regulatory updates. Stay informed and ahead with our expert analysis and industry news.
        </p>
      </section> */}



     

      {/* Featured Articles Section */}
      <section id="articles" className="featured-articles">
        <h2 className="fw-bolder">Featured Articles</h2>
        <div className="articles-grid">
            <article className="featured-article">
              <img
                src={img1}
                alt={`Article`}
                className="featured-imagee"
              />
              <div className="article-content">
                <h3>Critical Medical Gas Pipeline Systems</h3>
                <p>
                    Medical gas pipelines ensure the safe, continuous supply of essential gases like oxygen and nitrous oxide, supporting vital healthcare functions and patient care.
                </p>
              </div>
            </article>
            <article className="featured-article">
              <img
                src={img2}
                alt={`Article`}
                className="featured-imagee"
              />
              <div className="article-content">
                <h3>Monitoring Critical Medical Gas Systems</h3>
                <p>
                    Technicians ensure the safe and continuous operation of medical gas systems, essential for delivering life-saving gases in hospitals. Regular maintenance and monitoring are crucial for system reliability.
                </p>
              </div>
            </article>
            <article className="featured-article">
              <img
                src={`https://www.ny-engineers.com/hs-fs/hubfs/medical%20gas%20piping.jpg?width=2939&name=medical%20gas%20piping.jpg`}
                alt={`Article`}
                className="featured-imagee"
              />
              <div className="article-content">
                <h3>Oxygen Supply Systems in Healthcare</h3>
                <p>
                xygen delivery systems are critical for patient care, providing a reliable source of medical oxygen in hospitals and emergency settings. Proper maintenance ensures safe and effective oxygen flow during surgeries and treatments.
                </p>
              </div>
            </article>
           
            
          
        </div>
      </section>

      {/* Image Gallery Section */}
      <section id="gallery" className="image-gallery">
        <h2 className="fw-bolder">Image Gallery</h2>
        <div className="gallery-grid">
            <div className="gallery-item">
              <img
                src={img4}
                alt={`Gallery Image`}
                className="gallery-image"
              />
            </div>
            <div className="gallery-item">
              <img
                src={img5}
                alt={`Gallery Image`}
                className="gallery-image"
              />
            </div>
            <div className="gallery-item">
              <img
                src={img6}
                alt={`Gallery Image`}
                className="gallery-image"
              />
            </div>
            <div className="gallery-item">
              <img
                src={galary1}
                alt={`Gallery Image`}
                className="gallery-image"
              />
            </div>
            <div className="gallery-item">
              <img
                src={galary2}
                alt={`Gallery Image`}
                className="gallery-image"
              />
            </div>
            <div className="gallery-item">
              <img
                src={galary3}
                alt={`Gallery Image`}
                className="gallery-image"
              />
            </div>
        </div>
      </section>

      {/* Video Section */}
      {/* <section id="videos" className="blog-videos">
        <h2>Educational Videos</h2>
        <div className="video-grid">
            <div className="video-wrapper">
                <iframe
                width="928"
                height="522"
                src="https://www.youtube.com/embed/ReEp0HH91ZU"
                title={`Educational Video`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                ></iframe>
                <p className="video-description">
                Video on Medical Gas System
                </p>
            </div>
            <div className="video-wrapper">
                <iframe
                width="928"
                height="522"
                src="https://www.youtube.com/embed/ReEp0HH91ZU"
                title={`Educational Video`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                ></iframe>
                <p className="video-description">
                Video on Medical Gas System
                </p>
            </div>
            <div className="video-wrapper">
                <iframe
                width="928"
                height="522"
                src="https://www.youtube.com/embed/ReEp0HH91ZU"
                title={`Educational Video`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                ></iframe>
                <p className="video-description">
                Video on Medical Gas System
                </p>
            </div>
        </div>
        </section> */}

      {/* Contact Form */}
      {/* <section id="contact" className="contact-form">
        <h2  className="fw-bold">Contact Us</h2>
        <form>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <textarea placeholder="Message"></textarea>
          <button type="submit">Send Message</button>
        </form>
      </section> */}
    </div>
    </>
  );
};

export default BlogPage;
