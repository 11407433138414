// src/components/ThankYou.js
import React, { useEffect } from 'react';
import './ThankYou.css';  // Import the CSS file
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Set a timer to redirect after 4 seconds (4000 milliseconds)
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to the home page
    }, 4000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="thankyou-container">
      <div className="thankyou-content">
        <h1 className="thankyou-title">THANK YOU!</h1>
        <p className="thankyou-message">Thanks for getting in touch! We appreciate you contacting us. We will get back to you shortly.</p>
        <i className="fa fa-check thankyou-icon"></i>
      </div>
    </div>
  );
};

export default ThankYou;
