import React from 'react'
import img1 from "../image/certi1.jpg"
import img2 from "../image/certi2.jpg"
import img3 from "../image/certi3.jpg"
import img4 from "../image/certi4.jpg"

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './WhyMregg.css';



function Certificates() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };


  return (
    <>
       
       <Carousel
  swipeable={true}
  draggable={true}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={[]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
    
  <div >
    <img   className='img_ceri'   src={img1}  alt='dcs' />
  </div>
  <div>    
    <img   className='img_ceri'  src={img2}  alt='dcs' />

  </div>
  <div>   
     <img   className='img_ceri'  src={img3}  alt='dcs' />

  </div>
  <div>   
     <img   className='img_ceri'  src={img4}  alt='dcs' />

  </div>
  
  
</Carousel>
      
    </>
  )
}

export default Certificates
