import React, { useState } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Drawer, Menu } from 'antd';
import { IoIosLogOut } from 'react-icons/io';
import Logo from '../image/Logo.jpeg'
import Product from './Data.js'

// const Product = [
//   {
//     id: 1,
//     category: 'Electronics',
//     subcategories: [
//       { id: '1a', name: 'Mobiles' },
//       { id: '1b', name: 'Laptops' },
//     ],
//   },
//   {
//     id: 2,
//     category: 'Clothing',
//     subcategories: [
//       { id: '2a', name: 'Men' },
//       { id: '2b', name: 'Women' },
//     ],
//   },
// ];

// Menu items with routing
const items = [
  {
    key: 'sub0',
    label: <Link to='/' style={{color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>Home</Link>,
  },
  {
    key: 'sub1',
    label: <span style={{ color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>Product</span>,
    children: [
      {
        key: '1',
        label: <Link to='/product/1' style={{ textDecoration: 'none', color: '#1B9596' }}>Medical Gas Control & Automatic Changeover</Link>,
        children: [
          {
            key: '1-1',
            label: <Link to='/product/101' style={{ textDecoration: 'none', color: '#1B9596' }}>Semi Automatic Conrol Panel</Link>,
          },
          {
            key: '1-2',
            label: <Link to='/product/102' style={{ textDecoration: 'none', color: '#1B9596' }}>Fully Automatic Control Panel- Analog</Link>,
          },
          {
            key: '1-2',
            label: <Link to='/product/103' style={{ textDecoration: 'none', color: '#1B9596' }}>Fully Automatic Control Panel- Digital</Link>,
          },
          {
            key: '1-2',
            label: <Link to='/product/104' style={{ textDecoration: 'none', color: '#1B9596' }}>3 Source Fully Automatic Control Panel</Link>,
          },
          {
            key: '1-2',
            label: <Link to='/product/105' style={{ textDecoration: 'none', color: '#1B9596' }}>Fully Automatic Control Panel- Touch Screen</Link>,
          },
          {
            key: '1-2',
            label: <Link to='/product/106' style={{ textDecoration: 'none', color: '#1B9596' }}>3 Source Fully Automatic Control Panel- Touch Screen</Link>,
          },
        ],
      },
      {
        key: '2',
        label: <Link to='/product/107' style={{ textDecoration: 'none', color: '#1B9596' }}>Cylinder Framr Header/ Manifold</Link>,
      },
      {
        key: '3',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Alarm System</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/108' style={{ textDecoration: 'none', color: '#1B9596' }}>Digital Gas Alarm Systems</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/109' style={{ textDecoration: 'none', color: '#1B9596' }}>LCD Alarm Alert-1 (Med Touch)</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/110' style={{ textDecoration: 'none', color: '#1B9596' }}>Analog Gas Alarm Systems</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/111' style={{ textDecoration: 'none', color: '#1B9596' }}>Master Alarm</Link>,
          },
        ],
      },
      {
        key: '4',
        label: <Link to='/product/112' style={{ textDecoration: 'none', color: '#1B9596' }}>Area Valve Service Unit</Link>,
      },
      {
        key: '5',
        label: <Link to='/product/113' style={{ textDecoration: 'none', color: '#1B9596' }}>Compact Multi Valve AVSU</Link>,
      },
      {
        key: '6',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Medical Gas Outlet</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/114' style={{ textDecoration: 'none', color: '#1B9596' }}>British Standard Compatible (100% Metal) Nemo XP</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/115' style={{ textDecoration: 'none', color: '#1B9596' }}>British Standard Compatible Nemo safe</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/116' style={{ textDecoration: 'none', color: '#1B9596' }}>ISS Compatible</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/117' style={{ textDecoration: 'none', color: '#1B9596' }}>British Standard Compatible (Gem 10)</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/118' style={{ textDecoration: 'none', color: '#1B9596' }}>Puritan- Bennett Compatible</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/119' style={{ textDecoration: 'none', color: '#1B9596' }}>Parkodex Compatible</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/120' style={{ textDecoration: 'none', color: '#1B9596' }}>DIN Standard Compatible</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/121' style={{ textDecoration: 'none', color: '#1B9596' }}>Ohmeda Standard Compatibl</Link>,
          },
        ],
      },
      {
        key: '7',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Accessories</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/122' style={{ textDecoration: 'none', color: '#1B9596' }}>Regulators</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/123' style={{ textDecoration: 'none', color: '#1B9596' }}>Plastic Shaddles & Clamps</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/124' style={{ textDecoration: 'none', color: '#1B9596' }}>Isolation Ball Valve</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/125' style={{ textDecoration: 'none', color: '#1B9596' }}>Medical Gas Accessories</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/126' style={{ textDecoration: 'none', color: '#1B9596' }}>Pressure Gauges</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/127' style={{ textDecoration: 'none', color: '#1B9596' }}>Baskets</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/128' style={{ textDecoration: 'none', color: '#1B9596' }}>Flexible tail Pipe Connection</Link>,
          },
        ],
      },
      {
        key: '8',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Tubing for Medical Gases (Supply & Artificial Respiration)</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/129' style={{ textDecoration: 'none', color: '#1B9596' }}>Oxygen</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/130' style={{ textDecoration: 'none', color: '#1B9596' }}>Nitrous Oxide</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/131' style={{ textDecoration: 'none', color: '#1B9596' }}>Medical Air</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/132' style={{ textDecoration: 'none', color: '#1B9596' }}>Carbon Dioxide</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/133' style={{ textDecoration: 'none', color: '#1B9596' }}>Vacuum/ Suction</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/134' style={{ textDecoration: 'none', color: '#1B9596' }}>AGSS/WAGD</Link>,
          },
        ],
      },
      {
        key: '9',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Vacuum Regulators & Medical Suction Device</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/135' style={{ textDecoration: 'none', color: '#1B9596' }}>Direct Mount or bed Head Panel & Pendants</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/136' style={{ textDecoration: 'none', color: '#1B9596' }}>Reusable polycarbonate collection jar-1 L with Leak Proof self</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/137' style={{ textDecoration: 'none', color: '#1B9596' }}>Direct Mount on wall Outlet & collection Jar on medical rail below patient body level</Link>,
          },
        ],
      },
      {
        key: '10',
        label: <Link to='/product/138' style={{ textDecoration: 'none', color: '#1B9596' }}>Theatre Suction trolley</Link>,
      },
      {
        key: '11',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Medical Oxygen Flow meter</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/139' style={{ textDecoration: 'none', color: '#1B9596' }}>0-5 LPM</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/140' style={{ textDecoration: 'none', color: '#1B9596' }}>0-15 LPM</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/141' style={{ textDecoration: 'none', color: '#1B9596' }}>0-70 LPM</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/142' style={{ textDecoration: 'none', color: '#1B9596' }}>Humidifier bottle</Link>,
          },
        ],
      },
      {
        key: '12',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Monitor Stand</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/143' style={{ textDecoration: 'none', color: '#1B9596' }}>Monitor Stand Multi Movement</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/144' style={{ textDecoration: 'none', color: '#1B9596' }}>Monitor Stand</Link>,
          },
        ],
      },
      {
        key: '13',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Cubical Curtain Track</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/145' style={{ textDecoration: 'none', color: '#1B9596' }}>IV Hanger</Link>,
          },
        ],
      },
      {
        key: '14',
        label: <Link to='/product/146' style={{ textDecoration: 'none', color: '#1B9596' }}>Nurse Call System</Link>,
      },
      {
        key: '15',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Pendants</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/147' style={{ textDecoration: 'none', color: '#1B9596' }}>Stainless Steel Rigid Pendant</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/148' style={{ textDecoration: 'none', color: '#1B9596' }}>Endoscopy / Laproscopy Pendant</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/149' style={{ textDecoration: 'none', color: '#1B9596' }}>Shelf Type Double Arm Pendant</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/150' style={{ textDecoration: 'none', color: '#1B9596' }}>Double Arm Pendant</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/151' style={{ textDecoration: 'none', color: '#1B9596' }}>Swievel Pendant</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/152' style={{ textDecoration: 'none', color: '#1B9596' }}>ICU Pendant</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/153' style={{ textDecoration: 'none', color: '#1B9596' }}>Single Arm Pendant</Link>,
          },
        ],
      },
      {
        key: '16',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Bed Head Panel</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/154' style={{ textDecoration: 'none', color: '#1B9596' }}>Vertical panel</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/155' style={{ textDecoration: 'none', color: '#1B9596' }}>Horizontal Panel</Link>,
          },
        ],
      },
      {
        key: '17',
        label: <Link to='/product/156' style={{ textDecoration: 'none', color: '#1B9596' }}>Operation Theatre</Link>,
      },
      {
        key: '18',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Operation Theatre Accessories</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/157' style={{ textDecoration: 'none', color: '#1B9596' }}>Operation Theatre Control Panel</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/158' style={{ textDecoration: 'none', color: '#1B9596' }}>Surgeon Control Panel (Touch screen)</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/159' style={{ textDecoration: 'none', color: '#1B9596' }}>X-Ray Viewer</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/160' style={{ textDecoration: 'none', color: '#1B9596' }}>Writing Board</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/161' style={{ textDecoration: 'none', color: '#1B9596' }}>Hatch Box</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/162' style={{ textDecoration: 'none', color: '#1B9596' }}>Scrub Sink</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/163' style={{ textDecoration: 'none', color: '#1B9596' }}>Vinyl Flooring</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/164' style={{ textDecoration: 'none', color: '#1B9596' }}>P.R D</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/165' style={{ textDecoration: 'none', color: '#1B9596' }}>OT Door</Link>,
          },
        ],
      },
      {
        key: '19',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Air Compressor & Vacuum Pump</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/166' style={{ textDecoration: 'none', color: '#1B9596' }}>Vacuum Pump</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/167' style={{ textDecoration: 'none', color: '#1B9596' }}>Receiver Tank</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/168' style={{ textDecoration: 'none', color: '#1B9596' }}>Air Dryer</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/169' style={{ textDecoration: 'none', color: '#1B9596' }}>Air Compressor</Link>,
          },
        ],
      },
      {
        key: '20',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>Anaesthetic Gas Scavenging System</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/170' style={{ textDecoration: 'none', color: '#1B9596' }}>AGSS- Simplx</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/171' style={{ textDecoration: 'none', color: '#1B9596' }}>AGSS – Duplex</Link>,
          },
        ],
      },
      {
        key: '21',
        label: <Link to='/product/3' style={{ textDecoration: 'none', color: '#1B9596' }}>AGSS / WAGD Accessories</Link>,
        children: [
          {
            key: '3-1',
            label: <Link to='/product/172' style={{ textDecoration: 'none', color: '#1B9596' }}>Receiver / Interface Unit</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/173' style={{ textDecoration: 'none', color: '#1B9596' }}>Receiver/ Interface unit with transfer & Evacuation Hose Assembly (3M) conforming to BS EN ISO: 80601-2-13 : 2012+A2: 2019</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/174' style={{ textDecoration: 'none', color: '#1B9596' }}>3m BS Compatible AGSS Evacuation Hose Assembly (5m on request)</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/175' style={{ textDecoration: 'none', color: '#1B9596' }}>Remote switch</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/176' style={{ textDecoration: 'none', color: '#1B9596' }}>Scavenging Connector for Trasfer Hose-30mm ISO Conical Male & Female (pair)</Link>,
          },
          {
            key: '3-1',
            label: <Link to='/product/177' style={{ textDecoration: 'none', color: '#1B9596' }}>Kink- resistance transfer hose with a pair of 30mm Conical (ISO) Male & female connector</Link>,
          },
        ],
      },
    ],
  },
  {
    key: 'sub2',
    label: <Link to='/about' style={{color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>About Us</Link>,
  },
  {
    key: 'sub3',
    label: <Link to='/blog' style={{color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>Blog</Link>,
  },
  {
    key: 'sub4',
    label: <Link to='/contact' style={{color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>Contact Us</Link>,
  },
  {
    key: 'sub5',
    label: <Link to='/exhibition' style={{color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>Exhibition</Link>,
  },
  {
    key: 'sub6',
    label: <Link to='/client' style={{color: '#1B9596',textDecoration: 'none',fontSize: '18px'}}>Clientele</Link>,
  },
];

const Header = () => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate=useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    console.log('click ', e);
    setOpen(false)
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search/${searchQuery}`);
    }
  };

  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);

  const handleCategoryClick = (e, categoryId) => {
    e.stopPropagation();
    setActiveCategory(activeCategory === categoryId ? null : categoryId);
    setActiveSubcategory(null);
  };

  const handleSubcategoryClick = (e, subcategoryId) => {
    e.stopPropagation();
    setActiveSubcategory(activeSubcategory === subcategoryId ? null : subcategoryId);
  };

  return (
    <div className='container'>
      <Drawer
        placement={'left'}
        width={310}
        onClose={onClose}
        open={open}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={Logo}
              alt='logo'
              style={{height: '6.2rem',width: '8rem'}}
            />
          </div>
        }
      >
        <div className='d-flex flex-column justify-content-between'>
      <div>
        <Menu
          onClick={onClick}
          style={{
            width: 298,
            fontSize: '14px',
            marginLeft: '-1.6rem',
            color: '#1B9596'
          }}
          mode='inline'  // Set to inline for vertical expansion
          items={items}
          className='custom-menu'
        />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          width: '90%',
          paddingBottom: '20px',
          backgroundColor: '#fff',
          padding: '10px',
          textDecoration: 'none',
        }}
      >
      </div>
    </div>
      </Drawer>
      <div className='header' id='myHeader'>
        <div className='header-logo'>
          <a href='/'>
            <h1 className='pt-2'>
              <img
                src={Logo}
                alt='logo/img'
              />
            </h1>
          </a>
        </div>
        <div className='header-menu'>
        <Link to='/' style={{ textDecoration: 'none' }}>
            <div className='header_menu' id='dropdownMenuButton'>
              Home
            </div>
          </Link>
          


          <div className='dropdown border-0 bg-white'>
              <button
                className='btn btn-secondary dropdown-toggle border-0 bg-white text-primary fw-bolder'
                type='button'
                id='dropdownMenuButton'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Products
              </button>
              <div
                className='dropdown-menu border-0 bg-white p-0'
                aria-labelledby='dropdownMenuButton'
                style={{
                  position: 'absolute',  // Position dropdown absolutely
                  top: '100%',            // Position directly below the button
                  left: '0',             // Align with the button
                  margin: '0',           // Remove default margin
                  padding: '0',          // Remove default padding
                }}
              >
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '1')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Medical Gas Control & Automatic Changeover
                    </button>
                    {activeCategory === '1' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Semi Automatic Conrol Panel</Link>
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/102' style={{textDecoration: 'none',color: '#1B9596'}}>Fully Automatic Control Panel- Analog</Link>
                          </button>
                        </div>
                    </div>
                    <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/103' style={{textDecoration: 'none',color: '#1B9596'}}>Fully Automatic Control Panel- Digital</Link>
                          </button>
                        </div>
                    </div>
                    <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/104' style={{textDecoration: 'none',color: '#1B9596'}}>Source Fully Automatic Control Panel</Link>
                          </button>
                        </div>
                    </div>
                    <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/105' style={{textDecoration: 'none',color: '#1B9596'}}>Fully Automatic Control Panel- Touch Screen</Link>
                          </button>
                        </div>
                    </div>
                    <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/106' style={{textDecoration: 'none',color: '#1B9596'}}>Source Fully Automatic Control Panel- Touch Screen</Link>
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item'
                      onClick={(e) => handleCategoryClick(e, '2')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      <Link to='/product/107' style={{textDecoration: 'none',color: '#1B9596'}}>Cylinder Framr Header/ Manifold</Link>
                    </button>
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '21')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Alarm System
                    </button>
                    {activeCategory === '21' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/108' style={{textDecoration: 'none',color: '#1B9596'}}>Digital Gas Alarm Systems</Link>
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/109' style={{textDecoration: 'none',color: '#1B9596'}}>LCD Alarm Alert-1 (Med Touch)</Link>
                          </button>
                        </div>
                    </div>
                    <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/110' style={{textDecoration: 'none',color: '#1B9596'}}>Analog Gas Alarm Systems</Link>
                          </button>
                        </div>
                    </div>
                    <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/111' style={{textDecoration: 'none',color: '#1B9596'}}>Master Alarm</Link>
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item'
                      onClick={(e) => handleCategoryClick(e, '3')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      <Link to='/product/112' style={{textDecoration: 'none',color: '#1B9596'}}>Area Valve Service Unit</Link>
                      
                    </button>
                  </div>
                  <div>
                    <button
                      className='dropdown-item'
                      onClick={(e) => handleCategoryClick(e, '31')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Compact Multi Valve AVSU</Link>
                    </button>
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '32')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Medical Gas Outlet
                    </button>
                    {activeCategory === '32' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>British Standard Compatible (100% Metal) Nemo XP</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>British Standard Compatible Nemo safe</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>ISS Compatible</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>British Standard Compatible (Gem 10)</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Puritan- Bennett Compatible</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Parkodex Compatible</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>DIN Standard Compatible</Link>
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Ohmeda Standard Compatibl</Link>
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '4')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Accessories
                    </button>
                    {activeCategory === '4' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Regulators</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Plastic Shaddles & Clamps</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Isolation Ball Valve</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Medical Gas Accessories</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Pressure Gauges</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Baskets</Link>
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Flexible tail Pipe Connection</Link>
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '5')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Tubing for Medical Gases (Supply & Artificial Respiration)
                    </button>
                    {activeCategory === '5' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Oxygen</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Nitrous Oxide</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Medical Air</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Carbon Dioxide</Link>
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>Vacuum/ Suction</Link>
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            <Link to='/product/101' style={{textDecoration: 'none',color: '#1B9596'}}>AGSS/WAGD</Link>
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '6')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Vacuum Regulators & Medical Suction Device
                    </button>
                    {activeCategory === '6' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Direct Mount or bed Head Panel & Pendants
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Reusable polycarbonate collection jar-1 L with Leak Proof self 
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Direct Mount on wall Outlet & collection Jar on medical rail below patient body level
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item'
                      onClick={(e) => handleCategoryClick(e, '7')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Theatre Suction trolley
                    </button>
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '8')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Medical Oxygen Flow meter
                    </button>
                    {activeCategory === '8' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              0-5 LPM
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              0-15 LPM
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              0-70 LPM
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Humidifier bottle
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '9')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Monitor Stand
                    </button>
                    {activeCategory === '9' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Monitor Stand Multi Movement
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Monitor Stand
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '10')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Cubical Curtain Track
                    </button>
                    {activeCategory === '10' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              IV Hanger
                            </button>
                          </div>
                      </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item'
                      onClick={(e) => handleCategoryClick(e, '11')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Nurse Call System
                    </button>
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '12')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Pendants
                    </button>
                    {activeCategory === '12' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Stainless Steel Rigid Pendant
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Endoscopy / Laproscopy Pendant
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Shelf Type Double Arm Pendant
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Double Arm Pendant
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Swievel Pendant
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              ICU Pendant
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Single Arm Pendant
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '13')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Bed Head Panel
                    </button>
                    {activeCategory === '13' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Vertical panel
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Horizontal Panel
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item'
                      onClick={(e) => handleCategoryClick(e, '14')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Operation Theatre
                    </button>
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '15')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Operation Theatre Accessories
                    </button>
                    {activeCategory === '15' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Operation Theatre Control Panel
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Surgeon Control Panel (Touch screen)
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              X-Ray Viewer
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Writing Board
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Hatch Box
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Scrub Sink
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Vinyl Flooring
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              P.R D
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            OT Door
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '16')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Air Compressor & Vacuum Pump
                    </button>
                    {activeCategory === '16' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Vacuum Pump
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            Receiver Tank
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            Air Dryer
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Air Compressor
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '17')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      Anaesthetic Gas Scavenging System
                    </button>
                    {activeCategory === '17' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              AGSS- Simplx
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                          AGSS – Duplex
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
                  <div>
                    <button
                      className='dropdown-item dropdown-toggle'
                      onClick={(e) => handleCategoryClick(e, '18')}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '-1.2rem',
                        display: 'block',    // Make button block-level for proper width handling
                        width: '100%',       // Ensure button takes full width
                        textAlign: 'left',  // Align text to the left
                        color: '#1B9596'
                      }}
                    >
                      AGSS / WAGD Accessories
                    </button>
                    {activeCategory === '18' && (
                      <div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                              Receiver / Interface Unit
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            Receiver/ Interface unit with transfer & Evacuation Hose Assembly (3M) conforming to BS EN
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            3m BS Compatible AGSS Evacuation Hose Assembly (5m on request)
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            Remote switch
                            </button>
                          </div>
                      </div>
                      <div
                        className='dropdown-submenu'
                        style={{
                          position: 'relative',  // Position relative to the parent
                          paddingLeft: '15px',
                          padding: '0',         // Remove padding
                        }}
                      >
                          <div>
                            <button
                              className='dropdown-item'
                              onClick={(e) => handleSubcategoryClick(e, '11')}
                              style={{
                                cursor: 'pointer',
                                padding: '3px 20px',
                                marginTop: '-1.2rem',
                                display: 'block',    // Make button block-level for proper width handling
                                width: '100%',       // Ensure button takes full width
                                textAlign: 'left',  // Align text to the left
                                color: '#1B9596'
                              }}
                            >
                            Scavenging Connector for Trasfer Hose-30mm ISO Conical Male & Female (pair)
                            </button>
                          </div>
                      </div>
                      <div
                      className='dropdown-submenu'
                      style={{
                        position: 'relative',  // Position relative to the parent
                        paddingLeft: '15px',
                        padding: '0',         // Remove padding
                      }}
                    >
                        <div>
                          <button
                            className='dropdown-item'
                            onClick={(e) => handleSubcategoryClick(e, '12')}
                            style={{
                              cursor: 'pointer',
                              padding: '3px 20px',
                              marginTop: '-1.2rem',
                              display: 'block',    // Make button block-level for proper width handling
                              width: '100%',       // Ensure button takes full width
                              textAlign: 'left',  // Align text to the left
                              color: '#1B9596'
                            }}
                          >
                            Kink- resistance transfer hose with a pair of 30mm Conical (ISO) Male & female connector
                          </button>
                        </div>
                    </div>
                    </div>
                    )}
                  </div>
              </div>
            </div>




          <Link to='/about' style={{ textDecoration: 'none' }}>
            <div className='header_menu' id='dropdownMenuButton'>
              About us
            </div>
          </Link>
          <Link to='/blog' style={{ textDecoration: 'none' }}>
            <div className='header_menu' id='dropdownMenuButton'>
              Blog
            </div>
          </Link>
          <Link to='/contact' style={{ textDecoration: 'none' }}>
            <div className='header_menu' id='dropdownMenuButton'>
              Contact Us
            </div>
          </Link>
          <Link to='/exhibition' style={{ textDecoration: 'none' }}>
            <div className='header_menu' id='dropdownMenuButton'>
            Exhibitions
            </div>
          </Link>
          <Link to='/client' style={{ textDecoration: 'none' }}>
            <div className='header_menu' id='dropdownMenuButton'>
              Clientele
            </div>
          </Link>
        </div>
        <div className='header-search'>
          <form className='example' action='action_page.php'>
            <input
              className='header-searchbar'
              type='text'
              placeholder='Search..'
              name='search'
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className='header-search-button' type='submit' onClick={handleSearchSubmit}>
              Search
            </button>
          </form>
        </div>
        <div className='hamburger-icon'>
          <RxHamburgerMenu
            style={{ width: '25px', height: '25px', marginTop: '15px' }}
            onClick={showDrawer}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
