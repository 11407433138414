import React from 'react'
import WorkingHours from '../Components/WorkingHours'
import ContactForm from '../Components/ContactForm'

const About=()=> {
  return (
    <div>
        < WorkingHours />
        < ContactForm />
    </div>
  )
}

export default About